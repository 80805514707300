import { render, staticRenderFns } from "./OrderPreview.vue?vue&type=template&id=80f9c01e&scoped=true&"
import script from "./OrderPreview.vue?vue&type=script&lang=js&"
export * from "./OrderPreview.vue?vue&type=script&lang=js&"
import style0 from "./OrderPreview.vue?vue&type=style&index=0&id=80f9c01e&lang=scss&scoped=true&"
import style1 from "./OrderPreview.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "80f9c01e",
  null
  
)

export default component.exports